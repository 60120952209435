<template>
  <div class="help">
    <div class="nav">
      <div class="container">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">操作手册</el-menu-item>
          <el-menu-item index="2">常见问题</el-menu-item>
        </el-menu>
        <Serach size="mini" :serchValue="serchValue" style="height: 40px;width: 359px;" @serch="serch"></Serach>
      </div>
    </div>
    <div class="container">
      <div>
        <el-radio-group v-model="contentType" @change="contentTypeChange">
          <el-radio-button label="1">文章</el-radio-button>
          <el-radio-button label="3">操作视频</el-radio-button>
        </el-radio-group>
      </div>
      <ul class="text-list" v-if="contentType === '1'" v-loading="loading">
        <li v-for="item of listData" :key="item.id" @click="goDetail(item)">
          <div class="title">
            <h3>
              {{ item.title }}
            </h3>
            <div>
              <span class="more">查看详情</span>
              <img class="svghover" src="@/assets/images/new-ui/ic_xq_hover.svg" alt="">
            </div>
          </div>
          <div class="time"> {{ item.createTime|filterTimeNoHms }}</div>
        </li>
      </ul>
      <ul v-if="contentType === '3'" class="video-list" v-loading="loading">
        <li v-for="item of listData1" :key="item.id" @click="goDetail(item, 'video')">
          <div class="video-box">
            <video :poster="item.videoCover ? baseURL+ item.videoCover: ''" width="379" height="213" :autoplay="false" :src="baseURL + JSON.parse(item.attachFilePath)[0]"></video>
            <img src="@/assets/images/new-ui/video_hover@2x.png" alt="">
          </div>
          <div class="info">
            <h3>
              {{ item.title }}
            </h3>
            <div class="detail-time">
              <div class="detail">
                <div class="svghover"></div>
                <div class="normal"></div>
                <span class="more">查看详情</span>
              </div>
              <div class="time">
                <span class="day">{{ item.createTime|filterDay }}</span>
                <span class="month">/{{item.createTime|filterMonth}}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="no-data" v-if="contentType === '3' ? total === 0 && listData1.length === 0 : total === 0 && listData.length === 0">
        <img src="@/assets/images/new-ui/gy_pic_ss_none@2x.png" alt="">
        <div>对不起，搜索无结果</div>
      </div>
      <el-pagination
        v-if="total >= 12"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="currentChange"
        :page-sizes="[12]"
        layout="prev, pager, next"
        background
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>
<!-- overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; -->
<script>
import Serach from '@/components/Layout/SearchInput.vue'
import indexApi from "@/api/index.js";
import { mapState } from "vuex";
export default {
  components: {
    Serach
  },
  computed: {
    ...mapState(["baseURL"])
  },
  data() {
    return {
      loading: false,
      activeIndex: '1',
      serchValue: '',
      currentPage: 1,
      pageSize: 12,
      total: 0,
      listData: [],
      listData1: [],
      contentType: '1'
    }
  },
  created() {
    if (this.$route.query.type) {
      this.activeIndex = this.$route.query.type
      this.contentType = this.$route.query.contentType ?? '1'
    }
    this.renderList()
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    filterDay(val) {
      return moment(val).format("DD");
    },
    filterMonth(val) {
      return moment(val).format("MM");
    }
  },
  watch: {
    "$route.query"(newVal, oldVal) {
      this.activeIndex = newVal.type ?? this.activeIndex
      this.contentType = this.$route.query.contentType ?? '1'
      this.renderList()
    }
  },
  methods: {
    delHtmlTag (val) {
      if (val) {
        return val.replace(/<[^>]+>|&[nbsp;]+/g,"")
      }
      return ''
    },
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    goDetail(item, video) {
      this.$router.push({
        name: '帮助中心文章详情',
        query: {
          id: item.id,
          video
        }
      })
      this.clickNum(item.id)
    },
    contentTypeChange(val) {
      this.currentPage = 1;
      // this.renderList()
      this.$router.push({
        query: {
          ...this.$route.query,
          contentType: val
        }
      })
    },
    async renderList() {
      try {
        this.loading = true
        let { code, data, message } = await indexApi.findInformOrNewsList({
          page: this.currentPage,
          size: this.pageSize,
          module: '6', //(1.通知公告 2.最新动态 3政策法规 6 帮助中心)
          serviceType: this.activeIndex,
          sort: "asc",
          sortFiled: "sort",
          title: this.serchValue,
          contentType: this.contentType
        });
        if (code === 200) {
          if (this.contentType === '3') {
            this.listData1 = [...data.records]
            this.listData = []
          } else {
            this.listData = [...data.records]
            this.listData1 = []
          }
          this.total = data.total;
        }
      } finally {
        this.loading = false
      }
    },
    // 分页相关函数
    currentChange(val) {
      this.currentPage = val;
      this.renderList();
    },
    handleSelect(key) {
      this.activeIndex = key
      this.contentType = '1'
      this.currentPage = 1;
      // this.renderList()
      this.$router.push({
        query: {
          ...this.$route.query,
          type: key,
          contentType: '1'
        }
      })
    },
    serch(val) {
      this.currentPage = 1;
      this.serchValue = val
      this.renderList()
    }
  }
}
</script>

<style lang="less" scoped>
  .help {
    margin-bottom: 32px;
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #C3C3C3;
      margin-bottom: 32px;
    }
    .nav {
      background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
      box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
      height: 60px;
      margin-bottom: 20px;
      .container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          .el-menu-demo {
            border-bottom: none;
            background: none;
            .el-menu-item {
              padding: 0;
              font-size: 18px;
              margin-right: 56px;
              color: #999;
              border-bottom: none !important;
              transition: background-color .3s,color .3s;
              &:hover {
                color: #F36E31;
                background: none;
                border-bottom: none !important;
                font-weight: bold;
              }
            }
            .is-active {
              color: #333;
              border-bottom: none;
              position: relative;
              color: #F36E31;
              font-weight: bold;
              background: none;
              
            }
          }
      }
    }

    /deep/ .el-radio-group {
      display: flex;
      flex-wrap: wrap;
    }
    /deep/ .el-radio-button {
      margin-right: 14px;
      margin-bottom: 12px;
      .el-radio-button__inner {
        border: 1px solid #fff;
        background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
        border-radius: 4px 4px 4px 4px;
        box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05) !important;
        height: 40px;
        line-height: 38px;
        font-size: 16px;
        color: #20201E;
        font-weight: 400;
        width: 124px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s, color 0s;
        &:hover {
          border: 1px solid #F36E31;
          color: #fff;
          background: linear-gradient(90deg, #FF712B 0%, #FFA34A 100%);
          box-shadow: 0px 8px 16px 1px rgba(243,110,49,0.3);
        }
      }
      .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        border: 1px solid #F36E31;
        color: #fff;
        background: linear-gradient(90deg, #FF712B 0%, #FFA34A 100%);
        box-shadow: 0px 8px 16px 1px rgba(243,110,49,0.3) !important;
      }
    }
    .text-list {
      height: 100%;
      width: 100%;
      padding-top: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;
      >li {
        position: relative;
        padding-left: 15px;
        color: #333;
        font-size: 16px;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;

        }
        .time {
          color: #999;
          font-size: 14px;
          margin-top: 8px;
        }
        &:hover {
          .svghover {
            margin-right: 0px;
          }
          .more {
            opacity: 1;
          }
        }
        .more {
          color: #F36E31;
          opacity: 0;
          transition: opacity 0.3s;
          font-size: 14px;
          margin-right: 4px;
        }
        .svghover {
          transition: all 0.3s;
          margin-right: 10px;
        }
        h3 {
          width: 520px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:hover::after {
          border: 2px solid #F36E31;
        }
        &::after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          border: 2px solid #999999;
          border-radius: 6px;
          top: 29%;
          left: 0;
          transform: translateY(-50%);
          transition: border-color .3s;
        }
      }
    }
    .video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      &::after {
        content: '';
        height: 0;
        width: 374px;
      }
      li {
        width: 379px;
        height: 368px;
        transition: all 0.3s;
        border-radius: 8px;
        margin-bottom: 32px;
        cursor: pointer;
        .video-box {
          width: 379px;
          height: 213px;
          border-radius: 8px;
          overflow: hidden;
          background: #F3F5F7;
          position: relative;
          &::after {
            transition: all 0.4s;
            content: '';
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0);
            width: 379px;
            height: 213px;
            position: absolute;
          }
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            width: 32px;
            opacity: 0;
            transition: all 0.4s;
          }
        }
        .info {
          margin-top: 20px;
          padding: 0 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h3 {
            color: #333;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: 27px;
          }
          .detail-time {
            margin-top: 34px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .detail {
              display: flex;
              align-items: center;
              .svghover {
                opacity: 0;
                width: 24px;
                height: 24px;
                transition: all 0.4s;
                background-image: url(~@/assets/images/new-ui/ic_xq_hover.svg);
              }
              .normal {
                transition: all 0.4s;
                position: absolute;
                left: 0;
                top: 0;
                width: 24px;
                height: 24px;
                margin-top: 4px;
                background-image: url(~@/assets/images/new-ui/ic_xq_normal.svg);
                background-position: right;
              }
              .more {
                color: #F36E31;
                font-size: 14px;
                opacity: 0;
                transition: all 0.4s;
                margin-left: 4px;
              }
            }
            .time {
              display: flex;
              align-items: flex-end;
              font-weight: bold;
              .day {
                color: #333;
                font-size: 24px;
              }
              .month {
                color: #999;
                font-size: 16px;
              }
            }
          }
        }
        video {
          position: relative;
          transition: all 0.4s;
        }
        &:hover {
          box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
          .video-box img {
            opacity: 1;
          }
          .video-box::after {
            content: '';
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3);
            width: 379px;
            height: 213px;
            position: absolute;
          }
          video {
            transform: scale(1.2);
          }
          .normal {
            opacity: 0 !important;
            margin-left: 6px;
          }
          .svghover {
            opacity: 1 !important;
            margin-left: 6px;
          }
          .more {
            opacity: 1 !important;
          }
          h3 {
            color: #F36E31 !important;
          }
        }

      }
    }
}
</style>